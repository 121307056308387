<template>
    <div class="story-chapter">
        <ui-button color="transparent" @clicked="onClick">Глава {{ chapterNumber }}</ui-button>
        <div class="story-chapter__name">
            <span>{{ chapterData.title }}</span>
        </div>
        <div class="story-chapter__publish-settings"
             :class="{'story-chapter__publish-settings--disabled': disabled}"
        >
            <div v-if="disabled" class="story-chapter__publish-disable-tooltip" v-b-tooltip.hover
                 title="Необходимо назначить категорию"/>
            <input type="checkbox" name="publishStatus" :checked="published" v-model="published"/>
            <span>Опубликовано</span>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import bus from '@/plugins/bus';

export default {
    props: ['chapterId', 'storyId', 'chapterData', 'order', 'chapterNumber'],
    data: () => ({
        published: false,
        dataReady: false
    }),
    components: {
        UiButton: () => import('../../components/ui/UiButton')
    },
    computed: {
        ...mapGetters('firebase', ['getStoryById']),
        disabled() {
            return this.getStoryById(this.storyId).categories.length === 0;
        },
    },
    watch: {
        published(value) {
            this.dataReady && this.onPublishChapterCheckboxChanged(value);
        }
    },
    created() {
        this.published = this.chapterData.is_published;
        setTimeout(() => {
            this.dataReady = true;
        }, 50);
    },
    methods: {
        ...mapActions('firebase', ['setStoryChapterPublishState']),
        async onPublishChapterCheckboxChanged(isPublished) {
            const res = await this.setStoryChapterPublishState({
                storyId: parseInt(this.storyId, 10),
                chapterId: this.chapterId,
                isPublished
            });

            if (!res.error)
                bus.$emit('show-notification-message', 'Сохранено');
            else
                bus.$emit('show-notification-message', 'Ошибка');
        },
        async onClick() {
            await this.$router.push({
                name: 'chapters',
                params: {
                    chapterId: this.chapterData.id,
                    storyId: parseInt(this.storyId, 10),
                    chapterData: this.chapterData
                },
                hash: `/${this.chapterData.id}`
            });
        },
    }
};
</script>

<style lang="scss">
.story-chapter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    height: 72px;
    border-bottom: 1px solid #9AAABC;
    margin-bottom: 12px;

    .ui-button {
        width: auto;
        min-width: 130px;
        height: 40px;
        font-weight: 400;
    }

    &__name {
        display: flex;
        margin-left: 24px;
        align-items: center;
        min-width: 250px;
    }

    &__cover {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 16px;
        background-size: cover;
        background-position: center;
    }

    &__status {
        font-weight: 400;
    }

    &__publish-settings {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        input {
            margin-right: 6px;
        }

        &--disabled input {
            pointer-events: none;
        }
    }

    &__publish-disable-tooltip {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>
